import { Icon, Text } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import styles from './PerDiem/PerDiem.module.css';
import {
  DocumentCurrency,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { CreatePerDiemActionMenuButton } from '../CreatePerDiemActionMenuButton';
import clsx from 'clsx';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';

interface PerDiemDetailsCardWithActionProps {
  creatingPerDiem: boolean;
  onCreatePerDiem: () => void;
  reimbursementItems?: ReimbursementItemForList[];
}

export const PerDiemDetailsCardWithAction = ({
  creatingPerDiem,
  onCreatePerDiem,
  reimbursementItems = [],
}: PerDiemDetailsCardWithActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { setSelectedItemId } = useReimbursementFormsContext();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const perDiem = reimbursementItems.find(
    item => item.type === ReimbursementItemType.PerDiem
  );

  const { id, totalAmount } = perDiem ?? {};

  const formattedTotalAmount = !!id
    ? transactionAmountFormatter({
        value: totalAmount?.amount ?? 0,
        precision: totalAmount?.precision ?? 2,
        currency: totalAmount?.currency ?? DocumentCurrency.Eur,
      })
    : undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!id) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    setSelectedItemId(id);
  };

  return (
    <div className="grid gap-[1px] content-start">
      <div
        className={styles.card}
        data-clickable={!!id}
        onClick={handleClick}
        data-rounded-corners="top"
      >
        <div className="grid grid-cols-[auto_1fr_auto] gap-6 place-items-start">
          <div className={styles.iconWrapper}>
            <Icon icon="calendarDays" size="space20" />
          </div>
          <div className="grid gap-2 justify-self-start">
            <Text fontSize="xlarge" fontWeight="semibold">
              {t('reimbursementView.middleSection.form.perDiem.title')}
            </Text>
            <Text fontSize="basic">
              {t('reimbursementView.middleSection.form.perDiem.description')}
            </Text>
          </div>
          {formattedTotalAmount && (
            <Text fontSize="basic" fontWeight="semibold">
              {formattedTotalAmount}
            </Text>
          )}
        </div>
      </div>
      <div
        className={clsx('grid place-items-center', styles.card)}
        data-rounded-corners="bottom"
      >
        <CreatePerDiemActionMenuButton
          isDisabled={!!id}
          creatingPerDiem={creatingPerDiem}
          onCreatePerDiem={onCreatePerDiem}
        />
      </div>
    </div>
  );
};
