import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTimeField } from 'components/HookFormFields/HookFormTimeField';
import { useTranslation } from 'react-i18next';
import { generateSegmentFieldNames } from './utils/generatePerDiemFieldNames';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Button, useTheme } from '@candisio/design-system';
import styles from './PerDiem/PerDiem.module.css';
import { PerDiemLocationField } from './PerDiemLocationField';
import { UseFormReturn } from 'react-hook-form';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { useCallback, useMemo } from 'react';
import { usePerDiemLocationFieldOptions } from './utils/usePerDiemLocationFieldOptions';

interface PerDiemSegmentItemProps {
  formIndex: number;
  segmentIndex: number;
  isExcluded: boolean;
  canRemoveSegment: boolean;
  clearableField: string | undefined;
  isLastSegment: boolean;
  onCleareableField: (field?: string) => () => void;
  onUpdatePerDiem: (index: number) => () => Promise<void>;
  onRemoveSegment: (index: number) => void;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
}
const MESSAGE_KEY =
  'reimbursementView.middleSection.form.perDiem.segmentFields.location.infoTooltip';

export const PerDiemSegmentItem = ({
  formIndex,
  segmentIndex,
  isExcluded,
  clearableField,
  onCleareableField,
  onUpdatePerDiem,
  onRemoveSegment,
  canRemoveSegment,
  isLastSegment,
  formMethods,
}: PerDiemSegmentItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { colors } = useTheme();
  const fields = generateSegmentFieldNames(formIndex, segmentIndex);

  const { watch, trigger } = formMethods;

  const endDate = watch(fields.endDate);

  const formattedEndDate = useMemo(() => {
    return endDate ? new Date(endDate).toISOString() : new Date().toISOString();
  }, [endDate]);

  const perDiemLocationFieldOptions = usePerDiemLocationFieldOptions({
    endDate: formattedEndDate,
  });

  const handleTriggerLocationValidation = useCallback(() => {
    const shouldReTriggerLocationValidation = !!endDate;

    if (shouldReTriggerLocationValidation) {
      void trigger(fields.location);
    }
  }, [endDate, fields.location, trigger]);

  return (
    <div>
      <div
        className={styles.segmentContainer}
        data-removable={canRemoveSegment}
      >
        {canRemoveSegment && (
          <div className={styles.removeButton}>
            <Button
              icon="failCircle"
              variant="tertiary"
              size="small"
              label={t(
                'reimbursementView.middleSection.form.perDiem.ctaRemoveSegment'
              )}
              onClick={() => onRemoveSegment(segmentIndex)}
              style={{ color: colors.gray500 }}
            />
          </div>
        )}
        <div className="grid grid-cols-2 gap-2 items-end">
          <HookFormDatePickerField
            name={fields.startDate}
            readOnly={isExcluded || !isLastSegment}
            label={t('reimbursementView.middleSection.form.perDiem.from')}
            onBlur={() => {
              if (isExcluded || !isLastSegment) return;
              onUpdatePerDiem(formIndex)();
            }}
            clearable={clearableField === fields.startDate}
            onFocus={onCleareableField(fields.startDate)}
          />
          <HookFormTimeField
            name={fields.startTime}
            readOnly={isExcluded || !isLastSegment}
            aria-label={t(
              'reimbursementView.middleSection.form.perDiem.segmentFields.startTime'
            )}
            onBlur={() => {
              if (isExcluded || !isLastSegment) return;
              onUpdatePerDiem(formIndex)();
            }}
          />
        </div>
        <div className="grid grid-cols-2 gap-2 items-end">
          <HookFormDatePickerField
            name={fields.endDate}
            readOnly={isExcluded || !isLastSegment}
            label={t('reimbursementView.middleSection.form.perDiem.to')}
            onBlur={() => {
              if (isExcluded || !isLastSegment) return;
              handleTriggerLocationValidation();
              onUpdatePerDiem(formIndex)();
            }}
            clearable={clearableField === fields.endDate}
            onFocus={onCleareableField(fields.endDate)}
          />

          <HookFormTimeField
            name={fields.endTime}
            readOnly={isExcluded || !isLastSegment}
            aria-label={t(
              'reimbursementView.middleSection.form.perDiem.segmentFields.endTime'
            )}
            onBlur={() => {
              if (isExcluded || !isLastSegment) return;
              onUpdatePerDiem(formIndex)();
            }}
          />
        </div>
        <PerDiemLocationField
          name={fields.location}
          readOnly={isExcluded || !isLastSegment}
          label={t(
            'reimbursementView.middleSection.form.perDiem.segmentFields.location.label'
          )}
          infoTooltip={
            canRemoveSegment
              ? {
                  message: t(MESSAGE_KEY),
                }
              : undefined
          }
          onBlur={() => {
            if (isExcluded || !isLastSegment) return;
            onUpdatePerDiem(formIndex)();
          }}
          {...perDiemLocationFieldOptions}
        />
      </div>
      <div className="border-t border-gray-250" />
    </div>
  );
};
