import { useReimbursementItemsSectionComponent } from 'views/Reimbursement/hooks/useReimbursementItemsSectionComponent';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { useExpenseFormsHelpers } from './utils/useExpenseFormsHelpers';
import { TravelPromotion } from './TravelPromotion/TravelPromotion';
import { PerDiemItemDetails } from 'views/Reimbursement/hooks/usePerDiemItemDetails';

export interface ReimbursementItemsSectionContainerProps {
  reimbursement?: Reimbursement;
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
  reimbursementItems?: ReimbursementItemForList[];
  perDiemItemDetails?: PerDiemItemDetails;
}

export const ReimbursementItemsSectionContainer = ({
  reimbursement,
  formHelpers,
  reimbursementItems,
  perDiemItemDetails,
}: ReimbursementItemsSectionContainerProps) => {
  const ReimbursementItemsSection =
    useReimbursementItemsSectionComponent(reimbursement);
  const {
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
    handleCreatePerDiem,
    isCreatingExpenseItem,
    isCreatePerDiemPending,
  } = useReimbursementMenuButtonActions({ formHelpers });

  return (
    <div className="grid gap-4">
      <ReimbursementItemsSection
        creatingExpense={isCreatingExpenseItem}
        onGeneralExpenseClick={handleCreateGeneralExpense}
        onHospitalityExpenseClick={handleCreateHospitalityExpense}
        reimbursementItems={reimbursementItems}
        onCreatePerDiem={handleCreatePerDiem}
        creatingPerDiem={isCreatePerDiemPending}
        perDiemItemDetails={perDiemItemDetails}
      />
      <TravelPromotion />
    </div>
  );
};
