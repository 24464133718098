import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { ExpenseDetailsCardWithAction } from './ExpenseDetailsCardWithAction';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { PerDiemDetailsCardWithAction } from './PerDiemDetailsCardWithAction';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';

interface ReimbursementItemSummaryWithActionContainerProps {
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
  isPromotion?: boolean;
  creatingPerDiem: boolean;
  onCreatePerDiem: () => void;
  reimbursementItems?: ReimbursementItemForList[];
}

export const ReimbursementItemSummaryWithActionContainer = ({
  creatingExpense,
  creatingPerDiem,
  onCreatePerDiem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  isPromotion,
  reimbursementItems,
}: ReimbursementItemSummaryWithActionContainerProps) => {
  const { canUsePerDiemReimbursement } = useReimbursement();

  const { transactionAmountFormatter } = useMoneyFormatter();

  const filteredExpenseItems = (reimbursementItems ?? []).filter(item =>
    [
      ReimbursementItemType.GeneralExpense,
      ReimbursementItemType.HospitalityExpense,
    ].includes(item.type)
  );

  // XXX : This value should be coming from BE since amounts could be in different currencies.
  // it will be fixed in here -> https://candis.atlassian.net/jira/software/projects/TA/boards/64/backlog?selectedIssue=TA-5219
  const totalAmount = filteredExpenseItems.reduce(
    (acc, value) => acc + (value.totalAmount?.amount ?? 0),
    0
  );

  const formattedTotalAmount = transactionAmountFormatter({
    precision: 2,
    currency: 'EUR',
    value: totalAmount,
  });

  const expenseDetailsProps = {
    totalAmount: formattedTotalAmount,
    creatingExpense,
    onGeneralExpenseClick,
    onHospitalityExpenseClick,
    isPromotion,
    expenseCount: filteredExpenseItems.length ?? 0,
    reimbursementItems: filteredExpenseItems,
  };

  return (
    <div className="grid gap-4">
      <ExpenseDetailsCardWithAction {...expenseDetailsProps} />
      {canUsePerDiemReimbursement && (
        <PerDiemDetailsCardWithAction
          creatingPerDiem={creatingPerDiem}
          onCreatePerDiem={onCreatePerDiem}
          reimbursementItems={reimbursementItems}
        />
      )}
    </div>
  );
};
