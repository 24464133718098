import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { PerDiemDetailsCard } from './PerDiemDetailsCard';
import { ExpenseDetailsCard } from './ExpenseDetailsCard';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';

interface ReimbursementItemSummaryContainerProps {
  totalAmount?: string;
  reimbursementItems: ReimbursementItemForList[];
}

export const ReimbursementItemSummaryContainer = ({
  reimbursementItems,
}: ReimbursementItemSummaryContainerProps) => {
  const { canUsePerDiemReimbursement } = useReimbursement();

  const { transactionAmountFormatter } = useMoneyFormatter();

  const filteredReimbursementItems = reimbursementItems.filter(item =>
    [
      ReimbursementItemType.GeneralExpense,
      ReimbursementItemType.HospitalityExpense,
    ].includes(item.type)
  );

  // XXX : This value should be coming from BE since amounts could be in different currencies.
  // it will be fixed in here -> https://candis.atlassian.net/jira/software/projects/TA/boards/64/backlog?selectedIssue=TA-5219
  const totalAmount = filteredReimbursementItems.reduce(
    (acc, value) => acc + (value.totalAmount?.amount ?? 0),
    0
  );

  const formattedTotalAmount = transactionAmountFormatter({
    precision: 2,
    currency: 'EUR',
    value: totalAmount,
  });

  const expenseDetailsProps = {
    totalAmount: formattedTotalAmount,
    expenseCount: filteredReimbursementItems.length ?? 0,
    reimbursementItems: filteredReimbursementItems,
  };

  return (
    <div className="grid gap-4">
      <ExpenseDetailsCard {...expenseDetailsProps} />
      {canUsePerDiemReimbursement && (
        <PerDiemDetailsCard reimbursementItems={reimbursementItems} />
      )}
    </div>
  );
};
