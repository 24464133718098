import { Table } from '@candisio/design-system/next';
import { ComponentProps, useMemo } from 'react';
import { DocumentsTableData, DocumentTableColumnKeys } from '../types';
import { createColumnHelper } from '@tanstack/react-table';
import { Header } from './Header';
import { WithHighlightsCell } from 'components/WithHighlightsCell/next/WithHighlightsCell';
import { Cell } from './components/Cell';
import { TransactionStatusTag } from 'components/Transactions/TransactionStatusTag/TransactionStatusTag';
import { StatusCell } from './components/StatusCell';
import { ExportStatusCell } from './components/ExportStatusCell';
import { DocumentTypeCell } from './components/DocumentTypeCell';
import { InvoiceCorrectionCell } from './components/InvoiceCorrectionCell';
import { AmountCellHighlightable } from '../Cells/next/AmountCellHighlightable';
import { AmountCellDocument } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/Cells/AmountCell';
import styles from './DocumentsTable.module.css';
import { PaymentInfoCellHighlightable } from './components/PaymentInfoCellHighlightable';
import { IbanCell } from './components/IbanCell';
import { DateCell } from './components/DateCell';
import { TagsCell } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/Cells/TagsCell';
import { UserWithStatusCell } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/Cells/UserWithStatusCell';
import { FileNameCell } from './components/FileNameCell';
import { FileSizeCell } from './components/FileSizeCell';
import { OpenInDatevCell } from './components/OpenInDatevCell';
import { SettlementPaymentStatusCell } from './components/SettlementPaymentStatusCell';
import { PaymentFrequencyCell } from './components/PaymentFrequencyCell';
import { GLACellHighlightable } from './components/GLACellHighlightable';
import { SplitsCell } from './components/SplitsCell';
import { ProvisionStatusCell } from './components/ProvisionStatusCell';
import { SplitsCellHighlightable } from './components/SplitsCellHighlightable';
import { IsPayableCell } from './components/IsPayableCell';
import { PaymentStatusCell } from './components/PaymentStatusCell';
import { DiscountDateWPercentageCell } from './components/DiscountDateWPercentageCell';
import { CreditCardPayment } from './components/CreditCardPayment';
import { StatementCell } from './components/StatementCell';
import { MultipleValueCell } from './components/MultipleValueCell';

type TableProps = ComponentProps<typeof Table<DocumentsTableData>>;

export interface DocumentsTableProps {
  data: DocumentsTableData[];
  onLoadMore: () => void;
  sorting?: TableProps['sorting'];
  setSorting?: TableProps['setSorting'];
  rowOverlay?: TableProps['rowOverlay'];
  isLoading?: TableProps['isLoading'];
  CellWrapper?: TableProps['CellWrapper'];
  shownColumns?: (keyof DocumentsTableData)[];
  isUsingSearchQuery?: boolean;
  shouldEnableSortByWithSearchQuery?: boolean;
  sortByFields?: DocumentTableColumnKeys[];
  columnWidths?: Partial<Record<DocumentTableColumnKeys, number>>;
}

const columnHelper = createColumnHelper<DocumentsTableData>();

export const defaultSortByFields: DocumentTableColumnKeys[] = [
  'transactionStatus',
  'exportStatus',
  'contact',
  'openInDatev',
  'settlementPaymentStatus',
  'netAmount',
  'provisionStatus',
  'status',
  'grossAmount',
  'createdAt',
  'isPayable',
  'paymentStatus',
  'invoiceDate',
  'dueDate',
  'discountDateWPercentage',
  'requestedAt',
];

const defaultColumnWidths: Partial<Record<DocumentTableColumnKeys, number>> = {
  tags: 260,
};

export const DocumentsTable = ({
  data,
  onLoadMore,
  sorting,
  setSorting,
  rowOverlay,
  isLoading,
  CellWrapper,
  shownColumns,
  shouldEnableSortByWithSearchQuery = false,
  isUsingSearchQuery = false,
  sortByFields = defaultSortByFields,
  columnWidths: columnWidthsProp,
}: DocumentsTableProps) => {
  const defaultColumn: TableProps['defaultColumn'] = useMemo(
    () => ({
      header: Header,
      cell: props => (
        <WithHighlightsCell cellProps={props} FallbackCell={Cell} />
      ),
    }),
    []
  );

  const columns = useMemo(() => {
    const disableSortBy =
      isUsingSearchQuery && !shouldEnableSortByWithSearchQuery;
    const shouldSortByField = (field: DocumentTableColumnKeys) =>
      sortByFields.includes(field);

    const columnWidths = {
      ...defaultColumnWidths,
      ...columnWidthsProp,
    };

    const allColumns = [
      columnHelper.accessor('transactionStatus', {
        // biome-ignore lint/style/noNonNullAssertion: TODO: null check
        cell: ({ getValue }) => <TransactionStatusTag status={getValue()!} />,
        enableSorting: shouldSortByField('transactionStatus'),
        size: columnWidths?.transactionStatus,
      }),
      columnHelper.accessor('status', {
        cell: StatusCell,
        enableSorting: !disableSortBy || shouldSortByField('status'),
        size: columnWidths?.status,
      }),
      columnHelper.accessor('exportStatus', {
        cell: ExportStatusCell,
        enableSorting: shouldSortByField('exportStatus'),
        size: columnWidths?.exportStatus,
      }),
      columnHelper.accessor('exportMethod', {
        enableSorting: shouldSortByField('exportMethod'),
        size: columnWidths?.exportMethod,
      }),
      columnHelper.accessor('contact', {
        enableSorting: shouldSortByField('contact'),
        size: columnWidths?.contact,
      }),
      columnHelper.accessor('accountsPayableNumber', {
        enableSorting: false,
        size: columnWidths?.accountsPayableNumber,
      }),
      columnHelper.accessor('accountsReceivableNumber', {
        enableSorting: false,
        size: columnWidths?.accountsReceivableNumber,
      }),
      columnHelper.accessor('documentType', {
        enableSorting: shouldSortByField('documentType'),
        cell: DocumentTypeCell,
        size: columnWidths?.documentType,
      }),
      columnHelper.accessor('isInvoiceCorrection', {
        enableSorting: shouldSortByField('isInvoiceCorrection'),
        cell: InvoiceCorrectionCell,
        size: columnWidths?.isInvoiceCorrection,
      }),
      columnHelper.accessor('grossAmount', {
        enableSorting: !disableSortBy && shouldSortByField('grossAmount'),
        cell: props => (
          <AmountCellHighlightable
            cellProps={props}
            FallbackCell={AmountCellDocument}
          />
        ),
        header: props => (
          <Header {...props} className={styles['amount-header']} />
        ),
        size: columnWidths?.grossAmount,
      }),
      columnHelper.accessor('paymentInfo', {
        enableSorting: shouldSortByField('paymentInfo'),
        cell: PaymentInfoCellHighlightable,
        size: columnWidths?.paymentInfo,
      }),
      columnHelper.accessor('iban', {
        enableSorting: shouldSortByField('iban'),
        cell: IbanCell,
        size: columnWidths?.iban,
      }),
      columnHelper.accessor('invoiceNumber', {
        enableSorting: shouldSortByField('invoiceNumber'),
        size: columnWidths?.invoiceNumber,
      }),
      columnHelper.accessor('invoiceDate', {
        enableSorting: !disableSortBy && shouldSortByField('invoiceDate'),
        cell: DateCell,
        size: columnWidths?.invoiceDate,
      }),
      columnHelper.accessor('dueDate', {
        enableSorting: !disableSortBy && shouldSortByField('dueDate'),
        cell: DateCell,
        size: columnWidths?.dueDate,
      }),
      columnHelper.accessor('tags', {
        enableSorting: shouldSortByField('tags'),
        cell: TagsCell,
        size: columnWidths?.tags,
      }),
      columnHelper.accessor('deliveryDate', {
        enableSorting: shouldSortByField('deliveryDate'),
        cell: DateCell,
        size: columnWidths?.deliveryDate,
      }),
      columnHelper.accessor('createdAt', {
        enableSorting: !disableSortBy && shouldSortByField('createdAt'),
        cell: DateCell,
        size: columnWidths?.createdAt,
      }),
      columnHelper.accessor('requester', {
        enableSorting: shouldSortByField('requester'),
        cell: UserWithStatusCell,
        size: columnWidths?.requester,
      }),
      columnHelper.accessor('approvers', {
        enableSorting: shouldSortByField('approvers'),
        cell: UserWithStatusCell,
        size: columnWidths?.approvers,
      }),
      columnHelper.accessor('fileName', {
        enableSorting: shouldSortByField('fileName'),
        cell: FileNameCell,
        size: columnWidths?.fileName,
      }),
      columnHelper.accessor('fileSize', {
        enableSorting: shouldSortByField('fileSize'),
        cell: FileSizeCell,
        size: columnWidths?.fileSize,
      }),
      columnHelper.accessor('openInDatev', {
        enableSorting: shouldSortByField('openInDatev'),
        cell: OpenInDatevCell,
        size: columnWidths?.openInDatev,
      }),
      columnHelper.accessor('settlementPaymentStatus', {
        enableSorting: shouldSortByField('settlementPaymentStatus'),
        cell: SettlementPaymentStatusCell,
        size: columnWidths?.settlementPaymentStatus,
      }),
      columnHelper.accessor('period', {
        enableSorting: shouldSortByField('period'),
        cell: PaymentFrequencyCell,
        size: columnWidths?.period,
      }),
      columnHelper.accessor('accountingArea', {
        enableSorting: shouldSortByField('accountingArea'),
        size: columnWidths?.accountingArea,
      }),
      columnHelper.accessor('generalLedgerAccount', {
        enableSorting: shouldSortByField('generalLedgerAccount'),
        cell: GLACellHighlightable,
        size: columnWidths?.generalLedgerAccount,
      }),
      columnHelper.accessor('account', {
        enableSorting: shouldSortByField('account'),
        cell: props => <SplitsCell {...props} value={props.getValue()} />,
        size: columnWidths?.account,
      }),
      columnHelper.accessor('netAmount', {
        enableSorting: shouldSortByField('netAmount'),
        cell: AmountCellDocument,
        size: columnWidths?.netAmount,
      }),
      columnHelper.accessor('provisionStatus', {
        enableSorting: shouldSortByField('provisionStatus'),
        cell: ProvisionStatusCell,
        size: columnWidths?.provisionStatus,
      }),
      columnHelper.accessor('costCenter', {
        enableSorting: shouldSortByField('costCenter'),
        cell: props => (
          <SplitsCellHighlightable {...props} value={props.getValue()} />
        ),
        size: columnWidths?.costCenter,
      }),
      columnHelper.accessor('costObject', {
        enableSorting: shouldSortByField('costObject'),
        cell: props => (
          <SplitsCellHighlightable {...props} value={props.getValue()} />
        ),
        size: columnWidths?.costObject,
      }),
      columnHelper.accessor('extraCostInfo', {
        enableSorting: shouldSortByField('extraCostInfo'),
        cell: props => (
          <SplitsCellHighlightable {...props} value={props.getValue()} />
        ),
        size: columnWidths?.extraCostInfo,
      }),
      columnHelper.accessor('artistSocialInsuranceCode', {
        enableSorting: shouldSortByField('artistSocialInsuranceCode'),
        cell: props => (
          <SplitsCellHighlightable {...props} value={props.getValue()} />
        ),
        size: columnWidths?.artistSocialInsuranceCode,
      }),
      columnHelper.accessor('note', {
        enableSorting: shouldSortByField('note'),
        cell: props => (
          <SplitsCellHighlightable {...props} value={props.getValue()} />
        ),
        size: columnWidths?.note,
      }),
      columnHelper.accessor('postingText', {
        enableSorting: shouldSortByField('postingText'),
        cell: props => (
          <SplitsCellHighlightable {...props} value={props.getValue()} />
        ),
        size: columnWidths?.postingText,
      }),
      columnHelper.accessor('isPayable', {
        enableSorting: !disableSortBy && shouldSortByField('isPayable'),
        cell: IsPayableCell,
        size: columnWidths?.isPayable,
      }),
      columnHelper.accessor('paymentStatus', {
        enableSorting: !disableSortBy && shouldSortByField('paymentStatus'),
        cell: PaymentStatusCell,
        size: columnWidths?.paymentStatus,
      }),
      columnHelper.accessor('paidAt', {
        enableSorting: shouldSortByField('paidAt'),
        cell: DateCell,
        size: columnWidths?.paidAt,
      }),
      columnHelper.accessor('discountDateWPercentage', {
        enableSorting:
          !disableSortBy && shouldSortByField('discountDateWPercentage'),
        cell: DiscountDateWPercentageCell,
        size: columnWidths?.discountDateWPercentage,
      }),
      columnHelper.accessor('requestedAt', {
        enableSorting: !disableSortBy && shouldSortByField('requestedAt'),
        cell: DateCell,
        size: columnWidths?.requestedAt,
      }),
      columnHelper.accessor('creditCardPayment', {
        enableSorting: shouldSortByField('creditCardPayment'),
        cell: CreditCardPayment,
        size: columnWidths?.creditCardPayment,
      }),
      columnHelper.accessor('statement', {
        enableSorting: shouldSortByField('statement'),
        cell: StatementCell,
        size: columnWidths?.statement,
      }),
      columnHelper.accessor('purchaseOrderNumber', {
        enableSorting: shouldSortByField('purchaseOrderNumber'),
        size: columnWidths?.purchaseOrderNumber,
      }),
      columnHelper.accessor('sapPurchaseOrderNumber', {
        enableSorting: shouldSortByField('sapPurchaseOrderNumber'),
        cell: MultipleValueCell,
        size: columnWidths?.sapPurchaseOrderNumber,
      }),
      columnHelper.accessor('goodsReceiptNumber', {
        enableSorting: false,
        cell: MultipleValueCell,
        size: columnWidths?.goodsReceiptNumber,
      }),
    ];

    return allColumns;
  }, [
    isUsingSearchQuery,
    sortByFields,
    shouldEnableSortByWithSearchQuery,
    columnWidthsProp,
  ]);

  const columnVisibility: TableProps['columnVisibility'] = useMemo(() => {
    if (!shownColumns) return undefined;
    return columns.reduce(
      (acc, column) => {
        const key = column.accessorKey as keyof DocumentsTableData;
        acc[key] = shownColumns.includes(key);
        return acc;
      },
      {} as Record<keyof DocumentsTableData, boolean>
    );
  }, [shownColumns, columns]);

  return (
    <div className="flex flex-col h-full w-full relative overflow-hidden bg-white rounded-b-medium">
      <Table
        data={data}
        columns={columns}
        defaultColumn={defaultColumn}
        onEndReached={onLoadMore}
        CellWrapper={CellWrapper}
        sorting={sorting}
        setSorting={setSorting}
        rowOverlay={rowOverlay}
        isLoading={isLoading}
        columnOrder={shownColumns}
        columnVisibility={columnVisibility}
      />
    </div>
  );
};
