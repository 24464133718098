export enum FEATURE_FLAGS {
  accountingAreas = 'candis_features.accounting_areas',
  allowOutgoingInvoiceForApi = 'candis_features.allow_outgoing_invoice_for_api',
  artistSocialInsuranceAndExtraCostInfo = 'candis_features.artist_social_insurance_and_extra_cost_info',
  bdsExportEnabled = 'candis_features.export_bds_enabled',
  bdsExportPaymentConditionsAndContacts = 'candis_features.export_payment_conditions_and_contacts_with_bds',
  bdsPermissionCheckFlow = 'candis_features.bds_permission_check_flow',
  bdsSetupWizard = 'candis_features.bds_setup_wizard',
  candisApi = 'candis_features.candis_api',
  addBicToOrganizationIbanList = 'candis_features.add_bic_to_organization_iban_list',
  consistentSortAndFiltering = 'candis_features.consistent_sort_filter_inbox_archive_approval',
  contactsExport = 'candis_features.contacts_export',
  coreDataApi = 'candis_features.core_data_api',
  coreDataApiHideTaxCodeCreation = 'candis_features.core_data_api_hide_tax_code_creation',
  creditCards = 'candis_features.credit_cards',
  creditCardsAutomatedMatching = 'candis_features.credit_cards_automated_matching',
  commentsMentionsDynamicResolution = 'candis_features.comments_mentions_dynamic_resolution',
  creditCardsHideSpecialFeatures = 'candis_features.hide_extra_credit_cards_features',
  creditCardsInvoiceNotNeededOnCard = 'candis_features.credit_cards_invoice_not_needed_on_card',
  creditCardsStatements = 'candis_features.credit_cards_bill_statement_payment',
  documentRelations = 'candis_features.document_relations',
  documentTagsMerge = 'candis_features.document_tags_merge',
  modifyEcmDocumentEditAccess = 'candis_features.modify_ecm_document_edit_access',
  documentTypesForOtherIntegrations = 'candis_features.document_types_for_other_integrations',
  documentUploadRework = 'candis_features.wip_document_upload',
  enableNewIndexInApprovalView = 'candis_features.enable_new_es_index_in_approval_view',
  enableNewIndexInInboxView = 'candis_features.enable_new_es_index_in_inbox_view',
  enterpriseContentManagement = 'candis_features.enterprise_content_management',
  errorVisibilityImprovement = 'candis_features.error_visibility_improvement',
  experimentalCandisCsvExport = 'candis_features.experimental_candis_csv_export',
  exportDatevBdsViaIntegrationSvc = 'candis_features.export_datev_bds_via_integration_svc',
  exportProvisions = 'candis_features.export_provisions',
  exportProvisionsDatevFormatInternal = 'candis_features.export_provisions_datev_format',
  inAppNotifications = 'candis_features.in_app_notifications',
  increasedLimitForWidgets = 'candis_features.use_increased_limit_for_insights_widgets',
  llmClassification = 'candis_features.llm_classification',
  mobileAppPromotion = 'candis_features.mobile_app_promotion',
  newDashboardPromotions = 'candis_features.new_dashboard_promotions',
  newCreditCardsLedgerSettings = 'candis_features.new_credit_cards_ledger_settings',
  newFieldsForIntegrationOther = 'candis_features.new_fields_for_integration_other',
  omitTransactionDatesOnLinkedInvoice = 'candis_features.omit_transaction_dates_on_linked_invoice',
  paymentConditionsExport = 'candis_features.payment_condition_export',
  permissionsManagement = 'candis_features.permissions_management',
  perDiemReimbursement = 'candis_features.enable_per_diem_reimbursements',
  purchaseOrderNumber = 'candis_features.purchase_order_number',
  reauthenticateBeforeRevealCardCredentials = 'candis_features.protect_reveal_cc_credentials_with_in_app_reauthentication',
  sapContacts = 'candis_features.sap_contacts',
  sapCsvExport = 'candis_features.experimental_sap_csv_export',
  sapNetAmounts = 'candis_features.sap_net_amount',
  sapPurchaseOrder = 'candis_features.sap_purchase_order',
  slackIntegration = 'candis_features.slack_integration',
  ssoConfig = 'candis_features.sso_config',
  suggestSplitBookings = 'candis_features.suggest_split_bookings_from_vat_rates',
  wipTableRefactorForEcmAllDocuments = 'candis_features.wip_table_refactor_for_ecm_all_documents',
  teamsManagement = 'candis_features.teams_management',
  travelExpenseManagement = 'candis_features.travel_expense_management',
  validateBookingAccountBaseOffIntegration = 'candis_features.validate_booking_accounts_based_off_integration',
  visualiseWaitingForClarification = 'candis_features.visualise_waiting_for_clarification',
  wipImportExportNotifications = 'candis_features.wip_navigation_import_export_notifications',
  wipShowUploadDocumentMenuIcon = 'candis_features.wip_show_upload_document_menu_icon',
  sapProjectCodes = 'candis_features.sap_project_codes',
  useNewAllDocumentsQuery = 'candis_features.use_new_all_documents_query',
  enableNewIndexInExportsView = 'candis_features.enable_new_es_index_in_export_view',
  enableNewIndexInPaymentsView = 'candis_features.enable_new_es_index_in_payments_view',
  sapGoodsReceipts = 'candis_features.sap_goods_receipts',
  dashboardRedesignMain = 'candis_features.dashboard_redesign_main',
  dashboardRedesignCreditCards = 'candis_features.dashboard_redesign_credit_cards',
  reauthenticatorBeforeAddAuthenticatorApp = 'candis_features.protect_authenticator_app_with_in_app_reauthentication',
  validatePostingDateRelativeInvoiceDate = 'candis_features.sap_posting_date_validation_for_invoices_with_linked_transaction',
  sapUdfCheck = 'candis_features.wip_sap_udf_check',
  wipBookingsTableForm = 'candis_features.wip_bookings_table_form',
  wipPowerSearch = 'candis_features.wip_power_search',
}
