import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  PerDiemStep,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useExpenseFormsHelpers } from '../components/MiddleSection/utils/useExpenseFormsHelpers';
import { ReimbursementItemSchema } from '../toolkit/reimbursementItemsFormSchema';
import { useCreateGeneralExpense } from './useCreateGeneralExpense';
import { useCreateHospitalityExpense } from './useCreateHospitalityExpense';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { useCreatePerDiem } from './useCreatePerDiem';
import { v4 as uuidV4 } from 'uuid';

interface UseReimbursementMenuButtonActionsProps {
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
}

export const useReimbursementMenuButtonActions = ({
  formHelpers,
}: UseReimbursementMenuButtonActionsProps) => {
  const { t } = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { updateSearchParam } = useMutateSearchParams();

  const { error } = useToastMessage();
  const { createHospitality, createHospitalityPending } =
    useCreateHospitalityExpense();

  const { createGeneralExpense, isCreateGeneralExpensePending } =
    useCreateGeneralExpense();

  const { createPerDiem, isCreatePerDiemPending } = useCreatePerDiem();

  const { onAppendForm } = formHelpers ?? {};

  const handleCreateHospitalityExpense = useCallback(async () => {
    const reimbursementItemId = await createHospitality();

    if (!reimbursementItemId) {
      return;
    }

    const defaultValues: ReimbursementItemSchema = {
      reimbursementItemId,
      reason: '',
      expenseDate: '',
      reimbursementItemType: 'hospitality',
      totalAmount: null,
      receiptAmount: null,
      isItemExcluded: false,
      location: '',
      tipAmount: null,
      internalGuests: null,
      externalGuests: null,
      files: [],
      invoiceNumber: '',
      itemStatus: ReimbursementItemStatus.Included,
      isExtractedDataAccepted: false,
    };

    onAppendForm?.(defaultValues, { shouldFocus: false });
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(reimbursementItemId);
  }, [createHospitality, onAppendForm, updateSearchParam]);

  const handleCreateGeneralExpense = useCallback(async () => {
    const { status, generalExpenseId } =
      await createGeneralExpense(reimbursementId);

    if (status === 'success' && generalExpenseId) {
      const defaultValues: ReimbursementItemSchema = {
        expenseDate: '',
        reimbursementItemId: generalExpenseId,
        files: [],
        reimbursementItemType: 'general',
        reason: '',
        totalAmount: null,
        isItemExcluded: false,
        invoiceNumber: '',
        itemStatus: ReimbursementItemStatus.Included,
        isExtractedDataAccepted: false,
      };

      onAppendForm?.(defaultValues, { shouldFocus: false });
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
      scrollToTarget(generalExpenseId);
    } else {
      error(t('reimbursementView.createErr'));
    }
  }, [
    createGeneralExpense,
    error,
    onAppendForm,
    reimbursementId,
    t,
    updateSearchParam,
  ]);

  const handleCreatePerDiem = useCallback(async () => {
    const perDiemId = await createPerDiem();

    if (!perDiemId) {
      return;
    }

    const defaultValues: ReimbursementItemSchema = {
      reimbursementItemId: perDiemId,
      reimbursementItemType: 'perDiem',
      isItemExcluded: false,
      itemStatus: ReimbursementItemStatus.Included,
      currentStep: PerDiemStep.Segments,
      days: [],
      segments: [
        {
          endDate: '',
          startDate: '',
          location: '',
          endTime: '',
          startTime: '',
          segmentId: uuidV4(),
        },
      ],
    };

    onAppendForm?.(defaultValues, { shouldFocus: false });
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(perDiemId);
  }, [onAppendForm, updateSearchParam, createPerDiem]);

  const isCreatingExpenseItem =
    createHospitalityPending || isCreateGeneralExpensePending;

  return {
    handleCreateHospitalityExpense,
    handleCreateGeneralExpense,
    isCreatingExpenseItem,
    handleCreatePerDiem,
    isCreatePerDiemPending,
  };
};
